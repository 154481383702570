import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnChanges, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { faMessage, faArrowRightToBracket, faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-report-2500-8',
  templateUrl: './report-2500-8.component.html',
  styleUrls: ['./report-2500-8.component.scss'],
  animations: [
    trigger('expand',[
      state('false', style({width: AUTO_STYLE})),
      state('true', style({width:'330px'})),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out'))
    ])
  ]
})


export class Report25008Component implements OnChanges {
  //icons
  faMessage = faMessage;
  faCommentSlash = faCommentSlash;
  faArrowRightToBracket = faArrowRightToBracket;

  report_id = input.required<number>();
  report_page_input = input<string>();
  report_page: number = 0;

  report: any;
  report_status: any;
  nav: string = '';
  comment_expand: boolean = false;

  comment_count: number = 0;

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly reportService: ReportService
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnChanges(): void {
    this.reportService.getReport(this.report_id()).subscribe((report: any) => {
      this.report = report.reports[0];
      this.setReportStatus();
      this.nav = '';
    })
  }

  save_close() {
    this.nav = 'close'
  }

  forward() {
    this.nav = 'forward'
  }

  back() {
    this.nav = 'back'
  }

  setReportStatus() {
    //TODO
    //true - complete
    //false - incomplete
    this.report_status = [
      { report_page: 1, status: false },
      { report_page: 2, status: false },
      { report_page: 3, status: false },
      { report_page: 4, status: false },
      { report_page: 5, status: false },
      { report_page: 6, status: false },
      { report_page: 7, status: false },
      { report_page: 8, status: false }
    ]
    this.setPage();
  }

  setPage() {
    //if no page passed in, set page to latest page not finished
    if (!this.report_page_input()) {
      const filtered_status = this.report_status.filter((status: any) => !(status.status))
      const lowestItem = filtered_status.reduce((prev: any, curr: any) => (prev.report_page < curr.report_page ? prev : curr));
      this.report_page = lowestItem.report_page;
    }
    else {
      this.report_page = (parseInt(this.report_page_input() ?? '') ?? 0);
    }
  }
}
