import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserMemberService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    ) { }

  getRoleList(): Array<object> {
    return [
      { text: "Team Lead", value: 0 },
      { text: "Assistant Team Lead", value: 1 },
      { text: "Team Member", value: 2 },
      { text: "Trainee", value: 3 },
    ];
  }

  getUserMember(user_id: number): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.get(
      `${environment.apiUrl}/report/member/${user_id}`, { params: params }
    );
  }

  addUserToReport(report_id: number, data: {
    user_id: number,
    role_id: number,
    editor: boolean,
    demobilize?: boolean,
    memberSkills?: any[],
  }): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.post(
      `${environment.apiUrl}/report/${report_id}/member`, data, { params: params }
    );
  }

  updateMember(user_id: number, report_id: number, data: any): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.put(
      `${environment.apiUrl}/report/${report_id}/member/${user_id}`, data, { params: params }
    );
  }

  removeMember(user_id: number, report_id: number): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.delete(
      `${environment.apiUrl}/report/${report_id}/member/${user_id}`, { params: params }
    );
  }
}