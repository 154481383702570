import { Component, OnInit, input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faUsers, faIdBadge, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subject } from 'rxjs';
import { AssessmentService } from 'src/app/services/assessment.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ReportService } from 'src/app/services/report.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-fire-overview',
  templateUrl: './fire-overview.component.html',
  styleUrl: './fire-overview.component.scss'
})
export class FireOverviewComponent implements OnInit {
  faFileLines = faFileLines;
  faIdBadge = faIdBadge;
  faUsers = faUsers;
  assessment_id = input.required<number>();
  tab = input.required<number>();
  report_num = input.required<number>();

  user: any;
  assessment: any;
  reports: any;
  fireObs = new BehaviorSubject<any>(null);
  updateFireSubject = new Subject<any>();


  //access
  isTeamLead: boolean = false;
  isROCO: boolean = false;
  isFOCO: boolean = false;
  isWO: boolean = false;
  isEditable: boolean = false;

  tabIndex = 0;

  constructor(
    public userService: UserService,
    private readonly titleService: Title,
    private readonly assessmentService: AssessmentService,
    private readonly dialogService: DialogService,
    private readonly reportService: ReportService,
  ) {
    this.titleService.setTitle("BAER - Fire Overview")
    this.updateFireSubject.subscribe((res: any) => {
      if (res == null) {
        this.loadAssessments()
      }
    })
  }

  ngOnInit() {
    if (this.tab()) {
      this.tabIndex = this.tab()
    }
    this.loadAssessments();
  }

  loadUser() {
    this.userService.getUserProfile().then(async (user) => {
      this.user = user;
      // 7-Washington Office Director
      // 0-Reporting Tool Administrator
      // 4-BAER National Coordinator
      // 16-WO Coordination
      // 17-WO Logistics
      this.isWO = user.baer_roles.find((obj: any) => [0, 4, 7, 16, 17].includes(obj.value))
      // 12- RO BAER Coordinator
      this.isROCO = user.baer_roles.find((obj: any) => [12].includes(obj.value))
      // 5-BAER Forest Unit Coordinator
      // 8- Forest BAER Coordinator
      this.isFOCO = user.baer_roles.find((obj: any) => [5, 8].includes(obj.value))
      // 1-BAER Team Leader
      const isTeamLead = user.baer_roles.find((obj: any) => [1].includes(obj.value))
      if(isTeamLead && (this.assessment.team_lead?.user_id == user.user_id)) {
        this.isTeamLead = true;
      }
      if (this.isWO ||
        (this.isTeamLead && (this.assessment.team_lead?.user_id == user.user_id)) ||
        (this.isFOCO && (this.assessment.forest_name == user.forest_name)) ||
        (this.isROCO && (this.assessment.region == user.region))
      ) this.isEditable = true;
    });
  }

  loadAssessments() {
    this.dialogService.openSpinner();
    this.assessmentService.getAssessmentByID(this.assessment_id()).subscribe({
      next: (assessment) => {
        this.assessment = assessment;
        this.fireObs.next(this.assessment);
        this.loadUser();
      },
      complete: () => {
        this.dialogService.closeSpinner();
      }
    });
    let reportRelations = '&relations=users,users.skills,users.user,supports,assessment,users.user.skills,users.user.skills.skill';

    this.reportService.getReportList(this.assessment_id(), reportRelations).subscribe({
      next: (data) => {
        data.reports.sort((reportA: any, reportB: any) => reportA.report_num - reportB.report_num)
        this.reports = data.reports;
        this.updateFireSubject.next({ "reports": this.reports })
      }
    });
  }

}
