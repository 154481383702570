import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReportService } from 'src/app/services/report.service';
import { Editor, Toolbar } from 'ngx-editor';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-analysis-summary',
  templateUrl: './analysis-summary.component.html',
  styleUrls: ['./analysis-summary.component.scss']
})


export class AnalysisSummaryComponent implements OnChanges, OnInit {

  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;

  editor1!: Editor;
  editor2!: Editor;
  editor3!: Editor;
  editor4!: Editor;
  editor5!: Editor;
  editor6!: Editor;
  editor7!: Editor;
  toolbar: Toolbar = [
    ["bold", "italic", "underline", "strike", "background_color", "bullet_list", "ordered_list"]
  ]

  report = input.required<any>();
  nav = input.required<string>();
  forests = []
  saveSubject = new Subject<any>();

  // close = input.required<boolean>();
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  form = this.formBuilder.group({
    acresBurned: this.formBuilder.array([]),
    
    fire_complex_name: ["",Validators.pattern(this.reg)],
    treatment_land: [0],
    treatment_channel: [0],
    treatment_road_trails: [0],
    treatment_protection_safety: [0],
  });
  get acresBurned() {
    return this.form.get("acresBurned") as FormArray;
  }

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnInit(): void {
    this.editor1 = new Editor();
    this.editor2 = new Editor();
    this.editor3 = new Editor();
    this.editor4 = new Editor();
    this.editor5 = new Editor();
    this.editor6 = new Editor();
    this.editor7 = new Editor();
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    this.save()
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 5 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 3 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save() {
    this.saveSubject.next(null)
  }   

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.router.navigate(['fire-overview'], { queryParams: {assessment_id: this.report().assessment_id } });
  }
}
