<div class="user-menu">
    <button mat-button [matMenuTriggerFor]="menu" #menutrigger="matMenuTrigger">
        <div class="user-icon">
            {{user?.first_name[0] | uppercase}}
        </div>
        <mat-icon *ngIf="!menutrigger.menuOpen" iconPositionEnd>expand_less</mat-icon>
        <mat-icon *ngIf="menutrigger.menuOpen" iconPositionEnd>expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
        <div class="user-menu-name">{{user?.first_name}} {{user?.last_name}}</div>
        <div class="user-menu-email"><mat-icon>email</mat-icon>&nbsp;{{user?.email}}</div>
        <a mat-menu-item class="user-menu-item"
        [routerLink]="['/user']" [queryParams]="{user_id: user?.user_id}"><mat-icon>account_circle</mat-icon>View Profile</a>
        <button mat-menu-item class="user-menu-item" (click)="logout()"><mat-icon>logout</mat-icon>Sign out</button>
    </mat-menu>
</div>