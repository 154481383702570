import { Component, Input, OnInit, input, model } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { faEdit, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { EditFireComponent } from 'src/app/routes/fire-tracker/edit-fire/edit-fire.component';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-view-reports',
  templateUrl: './view-reports.component.html',
  styleUrl: './view-reports.component.scss'
})
export class ViewReportsComponent implements OnInit {

  faEdit = faEdit;
  faFileLines = faFileLines;
  reportsTable = new MatTableDataSource<any>();
  displayedColumns: string[] = ['name', 'reportId', 'action_type', 'report_type', 'funding_requested', 'authorized_funding', 'sumbission_date', 'reportStatus', 'authorization_status', 'action'];

  edit_fire = model<boolean>();
  @Input() fire?: any;
  @Input() reports!: any;
  @Input() updateFireSubject?: Subject<any>;
  @Input() isTeamLead!: boolean;
  editable = input.required<boolean>();

  constructor(
    public dialog: MatDialog,
    private readonly reportService: ReportService,
  ) { }

  ngOnInit() {
    this.reportsTable.data = this.reports;
    this.updateFireSubject?.subscribe((res: any) => {
      if (res?.reports) {
        this.reportsTable.data = res.reports;
      }
    })
  }

  edit() {
    this.dialog.open(EditFireComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: this.fire,
      },
    }).afterClosed().subscribe((reload: boolean) => {
      if (reload) {
        this.updateFireSubject?.next(null)
      }
    })
  }

  setTooltip(reports: any) {
    reports.forEach((report: any) => {
      let tooltip = 'Team Lead(s)\n';
      for (let member of report.users.filter((member: any) => member.role_id === 0)) {
        tooltip += member.first_name + ' ' + member.last_name + '\n'
      }
      for (let member of report.users.filter((member: any) => member.role_id === 1)) {
        tooltip += member.first_name + ' ' + member.last_name + '\n'
      }
      tooltip += '\nTeam Member(s)\n'
      for (let member of report.users.filter((member: any) => member.role_id === 2)) {
        tooltip += member.first_name + ' ' + member.last_name + '\n'
      }
      for (let member of report.users.filter((member: any) => member.role_id === 3)) {
        tooltip += member.first_name + ' ' + member.last_name + '\n'
      }
      report.tooltip = tooltip;
    });
  }
}
