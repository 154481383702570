<div class="report-header" *ngIf="report">
    <div class="rh-left">
        <div class="rh-title">{{report.baer_name}} ({{report.report_num}})</div>
        <div class="rh-sub">
            2500-8 Report ID: {{report.report_id}}
            <span class="pipe-color"> | </span>
            Action: @if(report.report_num == 1){Initial}@else{Interim}
            <span class="pipe-color"> | </span>
            Report
            Status: <mat-chip [ngClass]="'chip-r-'+report.report_status_id">{{report.report_status_id |
                reportStatusBadge}}</mat-chip>
        </div>
    </div>
    <div class="rh-right">
        <button (click)="save_close()" style="height: 40px;font-weight: 700;" mat-flat-button color="accent">Save &
            Close</button>
    </div>
</div>
<div class="report-main" *ngIf="report">
    <div class="report-nav">
        <div class="rnav-header">
            <div>2500-8 Report</div>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report_status[0].status +' rnav-current-'+(report_page==1)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 1}">
                <div>
                    @if(report_page == 1){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report_status[0].status) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    1. Report Type
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report_status[1].status +' rnav-current-'+(report_page==2)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 2}">
                <div>
                    @if(report_page == 2){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report_status[1].status) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    2. Burned Area Description
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report_status[2].status +' rnav-current-'+(report_page==3)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 3}">
                <div>
                    @if(report_page == 3){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report_status[2].status) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    3. Watershed Condition
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report_status[3].status +' rnav-current-'+(report_page==4)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 4}">
                <div>
                    @if(report_page == 4){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report_status[3].status) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    4. Summary of Analysis
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report_status[4].status +' rnav-current-'+(report_page==5)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 5}">
                <div>
                    @if(report_page == 5){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report_status[4].status) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    5. Recommended Treatment Narratives
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report_status[5].status +' rnav-current-'+(report_page==6)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 6}">
                <div>
                    @if(report_page == 6){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report_status[5].status) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    6. Emergency Stabilization Treatments & Requested Funds
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report_status[6].status +' rnav-current-'+(report_page==7)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 7}">
                <div>
                    @if(report_page == 7){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report_status[6].status) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    7. Team & Supporting Documents
                </div>
            </a>
        </div>
        <div [ngClass]="'rnav-item rnav-status-'+report_status[7].status +' rnav-current-'+(report_page==8)">
            <a [routerLink]="'/2500-8-report'" [queryParams]="{report_id: report.report_id, report_page_input: 8}">
                <div>
                    @if(report_page == 8){
                    <mat-icon class="current-page">contrast</mat-icon>
                    }
                    @else {
                    @if(report_status[7].status) {<mat-icon>check_circle</mat-icon>}
                    @else {<mat-icon>panorama_fish_eye</mat-icon>}
                    }
                    8. Select Forest Unit Line Officer(s)
                </div>
            </a>
        </div>
    </div>
    <div class="report-right-area">
        <div class="report-area">
            <div class="report-section" *ngIf="report">
                @switch (report_page) {
                @case (1) {<app-report-type [report]="report" [nav]="nav"></app-report-type>}
                @case (2) {<app-burned-area [report]="report" [nav]="nav"></app-burned-area>}
                @case (3) {<app-watershed-condition [report]="report" [nav]="nav"></app-watershed-condition>}
                @case (4) {<app-analysis-summary [report]="report" [nav]="nav"></app-analysis-summary>}
                @case (5) {<app-recommended-treatment [report]="report" [nav]="nav"></app-recommended-treatment>}
                @case (6) {<app-emergency-stabilization [report]="report" [nav]="nav"></app-emergency-stabilization>}
                @default {<div>No Report Page exists for this ID. please try again. {{report_page}}</div>}
                }
            </div>
            <div class="report-comments" [@expand]="comment_expand">
                <button mat-icon-button aria-label="chat_button" class="icon-button" (click)="comment_expand = true"
                    *ngIf="!comment_expand">
                    <fa-icon [icon]="faMessage" class="chat-count" aria-label="chat"></fa-icon><span
                        class="button-text">{{comment_count}}</span>
                </button>
                <button class="comment-button" (click)="comment_expand = false" *ngIf="comment_expand">
                    Comments<span>({{comment_count}})</span><button mat-icon-button
                        class="icon-button"><mat-icon>keyboard_tab</mat-icon></button>
                </button>
                <div class="no-comments" *ngIf="comment_expand && (comment_count == 0)">
                    <fa-icon [icon]="faCommentSlash" class="chat-count" aria-label="no messages"></fa-icon>
                    <div class="no-comments-title">No Comments</div>
                    <div class="no-comments-text">
                        No revisions have been requested
                    </div>
                </div>
            </div>
        </div>
        <div class="report-footer">
            <div class="rf-left">
                @switch (report_page) {
                @case (1) {}
                @case (2) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>1. Report Type</a>}
                @case (3) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>2. Burned Area Description</a>}
                @case (4) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>3. Watershed Condition</a>}
                @case (5) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>4. Summary of Analysis</a>}
                @case (6) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>5. Recommended Treatment Narratives</a>}
                @case (7) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>6. Emergency Stabilization Treatments & Requested Funds</a>}
                @case (8) {<a (click)="back()"><mat-icon>arrow_back_ios</mat-icon>7. Team & Supporting Documents</a>}
                @default {}
                }

            </div>
            <div class="rf-right">
                @switch (report_page) {
                @case (1) {<a (click)="forward()">2. Burned Area Description<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (2) {<a (click)="forward()">3. Watershed Condition<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (3) {<a (click)="forward()">4. Summary of Analysis<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (4) {<a (click)="forward()">5. Recommended Treatment
                    Narratives<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (5) {<a (click)="forward()">6. Emergency Stabilization Treatments & Requested
                    Funds<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (6) {<a (click)="forward()">7. Team & Supporting
                    Documents<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (7) {<a (click)="forward()">8. Select Forest Unit Line
                    Officer(s)<mat-icon>arrow_forward_ios</mat-icon></a>}
                @case (8) {<a (click)="forward()"><button>Send for Review ></button></a>}
                @default {}
                }
            </div>
        </div>
    </div>
</div>