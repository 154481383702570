import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { faEdit, faTrash, faUsers, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { MatTableDataSource } from '@angular/material/table';
import { AssessmentService } from 'src/app/services/assessment.service';
import { UserService } from 'src/app/services/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormBuilder } from '@angular/forms';
import { NewUserComponent } from '../../user/new-user/new-user.component';
import { DeleteUserComponent } from '../../user/delete-user/delete-user.component';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, Subject, map, of, startWith } from 'rxjs';
import { RemoveUserComponent } from '../../user/remove-user/remove-user.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-user-roster',
  templateUrl: './user-roster.component.html',
  styleUrl: './user-roster.component.scss'
})
export class UserRosterComponent implements OnInit, OnDestroy {
  faEdit = faEdit;
  faTrash = faTrash;
  faUsers = faUsers;
  faUserSlash = faUserSlash;
  @Input() role: string = "ALL_USERS"
  @Input() currentUser: any;
  //roster page type filter
  @Input() allow_delete: boolean = true;
  @Input() allow_remove: boolean = true;
  //user type filter
  canEdit: boolean = false;
  canDelete: boolean = false;
  // forest / region filters
  @Input() filterForest: boolean = true;
  @Input() filterRegion: boolean = true;

  regions: any;
  selectedRegion: string = 'ANY';
  forest_names: any;
  agencies: any;
  skills: any;
  positions: any;
  user_count = 0;
  filtered_skills: Observable<any[]>;
  filtered_positions: Observable<any[]>;
  selectedSkillFlag = false;
  selectedPositionFlag = false;
  @ViewChild('skills') inputSkill?: ElementRef<HTMLInputElement>;
  @ViewChild('positions') inputPosition?: ElementRef<HTMLInputElement>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;
  pageLoaded = false;
  user_unfiltered_count = 0;
  filtered_forest_names: Observable<string[]>;
  selectedForestFlag = false;

  searchForm = this.formBuilder.group({
    name: [""],
    region: [{ value: "All", disabled: false }],
    forest_name: [{ value: "", disabled: false }],
    agency: ["-1"],
    skill: ["" as any],
    position: ["" as any],
    available: [""]
  });

  usersSource !: any;
  users = new MatTableDataSource<any>();
  displayedColumns!: string[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort;

  constructor(
    public dialog: MatDialog,
    private readonly dialogService: DialogService,
    private readonly assessmentService: AssessmentService,
    private readonly userService: UserService,
    private readonly _snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
  ) {
    this.regions = this.assessmentService.getRegionList();
    this.forest_names = this.assessmentService.getForestNameList();
    this.agencies = this.userService.getAgencyList();
    this.userService.getSkillList().subscribe(skillsList => this.skills = skillsList);

    this.filtered_skills = this.searchForm.controls["skill"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filterSkills((value as string) || ""))
    )
    this.filtered_positions = this.searchForm.controls["position"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filterPositions((value as string) || ""))
    )
    this.filtered_forest_names = this.searchForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  getPositions() {
    this.userService.getRoleList().subscribe(positionsList => {
      switch (this.role) {
        case "COORDINATORS":
          this.positions = positionsList.filter((role: any) => role.type.endsWith("CO"));
          break;
        case "LINE_OFFICER":
          this.positions = positionsList.filter((role: any) => role.type.endsWith("LO"));
          break;
        default:
          this.positions = positionsList;
      }
    });
  }

  private _filterSkills(value: any): string[] {
    if (value.text) value = value.text
    const filterValue = value.toLowerCase()
    return this.skills.filter((option: any) => option.text.toLowerCase().includes(filterValue))
  }

  private _filterPositions(value: any): string[] {
    if (value.text) value = value.text
    const filterValue = value.toLowerCase()
    return this.positions.filter((option: any) => option.text.toLowerCase().includes(filterValue))
  }

  skillFilter(): void {
    const filterValue = this.inputSkill?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_skills = of(this._filterSkills(filterValue));
  }

  positionFilter(): void {
    const filterValue = this.inputPosition?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_positions = of(this._filterPositions(filterValue));
  }

  clearSkillFilter(): void {
    if (!this.selectedSkillFlag) {
      this.searchForm.patchValue({
        skill: undefined,
      });
    }
    this.selectedSkillFlag = false
  }

  clearPositionFilter(): void {
    if (!this.selectedPositionFlag) {
      this.searchForm.patchValue({
        position: "",
      });
    }
    this.selectedPositionFlag = false
  }

  getSkillName(option: any) {
    return option ? option.text : "";
  }

  getPositionName(option: any) {
    return option ? option.text : "";
  }

  skillSelected() {
    this.selectedSkillFlag = true;
    this.search();
  }

  positionSelected() {
    this.selectedPositionFlag = true;
    this.search();
  }

  ngOnDestroy() {
    this._snackBar.dismiss();
  }

  ngOnInit(): void {
    this.getPositions();

    this.dialogService.openSpinner();
    //load information
    this.loadUsers();

    if (this.role == "COORDINATORS" || this.role == "LINE_OFFICER") {
      this.displayedColumns = [
        //'select', only for canDelete
        'name',
        'region',
        'forest_name',
        'positions'
        //'actions' only for canDelete / canEdit
      ]
    } else {
      this.displayedColumns = [
        //'select', only for canDelete
        'name',
        'region',
        'forest_name',
        'agency',
        'skills',
        'assignment_status'
        //'actions' only for canDelete / canEdit
      ]
    }
    //setup editability for leader
    // 1-BAER Team Leader
    const isTeamLead = this.currentUser.baer_roles?.find((obj: any) => [1].includes(obj.value))
    if (isTeamLead && this.role != "COORDINATORS") {
      this.canEdit = true;
      this.allow_delete = false;
      this.displayedColumns.unshift('select')
      if (!this.displayedColumns.includes('actions'))
        this.displayedColumns.push('actions')
    }
    //setup editability
    // 0-BAER Reporting Tool Administrator
    // 3-BAER Regional Coordinator
    // 4-BAER National Coordinator
    // 5-BAER Unit Coordinator
    // 7-Washington Office Director
    // 8-Forest BAER Coordinator
    // 9-Forest BAR Coordinator
    // 10-Forest READ Coordinator
    // 11-Forest Disaster Coordinator
    // 12-RO BAER Coordinator
    // 13-RO BAR Coordinator
    // 14-RO READ Coordinator
    // 15-RO Disaster Coordinator
    // 16-WO Coordination
    // 17-WO Logistics
    const canEdit = this.currentUser.baer_roles?.find((obj: any) => [0, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].includes(obj.value))
    const canDelete = this.currentUser.baer_roles?.find((obj: any) => [0, 3, 4, 5, 7, 10, 11, 12, 13, 14, 15, 16, 17].includes(obj.value))
    if (canEdit) {
      this.canEdit = true;
      if (!this.displayedColumns.includes('select'))
        this.displayedColumns.unshift('select')
      if (!this.displayedColumns.includes('actions'))
        this.displayedColumns.push('actions');
    }
    if (canDelete) {
      this.canDelete = true;
      this.allow_delete = true;
    }
  }

  loadUsers() {
    let type = 'ALL';
    switch (this.role) {
      case "COORDINATORS":
        type = 'CO';
        break;
      case "LINE_OFFICER":
        type = 'LO';
        break;
      case "UNASSIGNED":
        type = 'UNASSIGNED';
        break;
    }
    this.userService.getUserList(type).subscribe(data => {
      let users = data.users
      this.sortFlattenAssessments(users);
      this.setTooltip(users);
      this.users.data = users;
      this.usersSource = users;
      this.user_count = users.length;
      this.users.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'name':
            return item.last_name + item.first_name;
          case 'region':
            return item.region;
          case 'forest_name':
            return item.forest_name;
          case 'agency':
            return item.agency ? item.agency.text : undefined;
          case 'assignment_status': {
            let statSort = 0;
            if (!(item.available_personal ?? true)) statSort = 2;
            if (item.filteredAssessment) statSort = 1;
            return statSort;
          }
          default:
            return item.property;
        }
      }
      this.users.sort = this.sort;

      if (this.filterRegion) {
        this.searchForm.patchValue({
          region: this.currentUser.region,
        });
        this.search();
      }
      if (this.filterForest) {
        this.searchForm.patchValue({
          region: this.currentUser.region,
          forest_name: this.currentUser.forest_name,
        });
        this.searchForm.get('region')?.disable();
        this.searchForm.get('forest_name')?.disable();
        this.search();
      }
      this.pageLoaded = true;
      this.dialogService.closeSpinner();
    });
  }

  sortFlattenAssessments(users: any) {
    const today = new Date();
    for (let user of users) {
      let assessments: any = [];
      assessments = [...assessments, ...user.assessments];
      for (let report of user.reports) {
        assessments = [...assessments, ...[report.assessment]];
      }
      assessments = assessments.filter((x: any) => (new Date(x.close_out_date)) > today);
      assessments.sort((a: any, b: any) => a.close_out_date > b.close_out_date);
      user.filteredAssessment = assessments[0];

      let tooltip = '';
      if (user.filteredAssessment) {
        tooltip = 'Fire Assignment:\nFire name: ' + user.filteredAssessment.baer_name
        tooltip += '\nStart Date: ' + (new Date(user.filteredAssessment.tentative_start_week)).toLocaleDateString("en-US")
        tooltip += '\nClose Out: ' + (new Date(user.filteredAssessment.close_out_date)).toLocaleDateString("en-US")
      }
      user.assessmentTooltip = tooltip;
    }
  }

  ngAfterViewInit() {
    this.users.sort = this.sort;
    this.users.paginator = this.paginator;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  search(): void {
    let data = this.usersSource;
    this.user_unfiltered_count = data.length

    if (this.searchForm.getRawValue().region != 'All')
      data = data.filter((e: any) => e.region == this.searchForm.getRawValue().region);
    if ((this.searchForm.getRawValue().forest_name ?? '') != '')
      data = data.filter((e: any) => e.forest_name == this.searchForm.getRawValue().forest_name);

    if (this.filterForest) {
      this.user_unfiltered_count = data.length
    }

    if (this.searchForm.value.name != '')
      data = data.filter((e: any) => e.last_name.toLowerCase().includes(
        this.searchForm.value.name?.trim().toLowerCase())
        || e.first_name.toLowerCase().includes(this.searchForm.value.name?.trim().toLowerCase())
      );
    if (this.searchForm.value.agency != '-1')
      data = data.filter((e: any) => e.agency.value == this.searchForm.value.agency);
    if (this.searchForm.value.skill?.value != undefined) { //move selected skill to front
      data = data.filter((e: any) => {
        e.skillIndex = e.skills.map((e: any) => e.skill_id).indexOf(this.searchForm.value.skill.value)
        if (e.skillIndex != -1) {
          let tempSkill = e.skills[e.skillIndex];
          e.skills[e.skillIndex] = e.skills[0];
          e.skills[0] = tempSkill;
        }
        return e.skillIndex != -1;
      })
    }
    if (this.searchForm.value.position?.value != undefined) {
      data = data.filter((e: any) => {
        e.positionIndex = e.baer_roles.map((e: any) => e.value).indexOf(this.searchForm.value.position.value)
        if (e.positionIndex != -1) {
          let tempPosition = e.baer_roles[e.positionIndex];
          e.baer_roles[e.PositionIndex] = e.baer_roles[0];
          e.baer_roles[0] = tempPosition;
        }
        return e.positionIndex != -1;
      })
    }
    switch (this.searchForm.value.available) {
      case 'Available':
        data = data.filter((e: any) => e.filteredAssessment == undefined && (e.available_personal ?? true));
        break;
      case 'OnAssignment':
        data = data.filter((e: any) => (e.filteredAssessment != undefined) && ((e.available_personal ?? true)));
        break;
      case 'NotAvailable':
        data = data.filter((e: any) => !(e.available_personal ?? true));
        break;
      default:
        break;
    }

    this.setTooltip(data);
    this.sortFlattenAssessments(data);
    this.users.data = data;
    this.user_count = data.length;
  }

  setTooltip(users: any) {
    users.forEach((user: any) => {
      let tooltip = '';
      let tooltip2 = '';
      for (let i = 1; user.skills.length - 1 >= i; i++) {
        tooltip += user.skills[i].skill.text + '\n'
      }
      for (let i = 1; user.baer_roles.length - 1 >= i; i++) {
        tooltip2 += user.baer_roles[i].text + '\n'
      }
      user.tooltip = tooltip;
      user.tooltip2 = tooltip2
    });
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm.patchValue({
      name: "",
      region: "All",
      forest_name: undefined,
      agency: "-1",
      skill: "",
      position: "",
    });
    if (this.filterRegion) {
      this.searchForm.patchValue({
        region: this.currentUser.region,
      });
    }
    if (this.filterForest) {
      this.searchForm.patchValue({
        region: this.currentUser.region,
        forest_name: this.currentUser.forest_name,
      });
    }
    this.search();
  }

  addNewUser(): void {
    let user_type = "User"
    switch (this.role) {
      case "COORDINATORS":
        user_type = "Coordinator";
        break;
      case "LINE_OFFICER":
        user_type = "Line Officer";
        break;
    }
    this.dialog.open(NewUserComponent, {
      width: "700px",
      data: { 'user_type': user_type }, //User, Coordinator, Line Officer
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.loadUsers();
      }
    })
  }

  editUser(user: any): void {
    let user_type = "User"
    switch (this.role) {
      case "COORDINATORS":
        user_type = "Coordinator";
        break;
      case "LINE_OFFICER":
        user_type = "Line Officer";
        break;
    }
    this.dialog.open(NewUserComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        user: user,
        user_type: user_type
      }
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.loadUsers();
      }
    })
  }

  deleteUser(user: any): void {
    this.dialog.open(DeleteUserComponent, {
      width: "440px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        users: [user]
      }
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.loadUsers()
      }
    })
  }

  removeUser(user: any): void {
    this.dialog.open(RemoveUserComponent, {
      width: "440px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        role: this.getTabRole(),
        users: [user]
      }
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.loadUsers()
      }
    })
  }

  getTabRole() {
    let role: string | undefined = "BAER"
    switch (this.role) {
      case "COORDINATORS":
        role = "CO";
        break;
      case "LINE_OFFICER":
        role = "LO";
        break;
    }
    return role;
  }

  selectCheck() {
    this.openSnackBar();
  }

  openSnackBar() {
    let selectedUsers = this.users.data.filter(x => x.isSelected === true)
    if (selectedUsers.length > 0) {
      let subject = new Subject()
      this._snackBar.openFromComponent(DeleteUserSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        data: {
          length: selectedUsers.length,
          users: selectedUsers,
          subject: subject,
          allow_delete: this.allow_delete,
          allow_remove: this.allow_remove,
          canDelete: this.canDelete,
          role: this.getTabRole()
        }
      });
      subject.asObservable().subscribe(reload => {
        if (reload) {
          this.loadUsers();
        }
      })
    }
    else {
      this._snackBar.openFromComponent(DeleteUserSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
        data: {
          length: 0,
          allow_delete: this.allow_delete,
          allow_remove: this.allow_remove,
          canDelete: this.canDelete
        }
      })
    }
  }

  clearValue(event: any) {
    event.target.value = ""
  }

  searchRegionChanged(value: any): void {
    this.searchForm.patchValue({ forest_name: undefined })
    if (value == 'All') this.forest_names = this.assessmentService.getForestNameList();
    else this.forest_names = this.assessmentService.getForestNameList(this.searchForm.value.region ?? undefined);
  }

  forestNameSelected(): void {
    this.selectedForestFlag = true;
    this.search()
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forest_names.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }

  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  clearForest() {
    if (!this.selectedForestFlag) {
      this.searchForm.patchValue({
        forest_name: undefined
      })
    }
    this.selectedForestFlag = false;
  }
}

@Component({
  selector: 'delete-user-snackbar',
  templateUrl: 'delete-user-snackbar.html',
  styleUrls: ['./delete-user-snackbar.component.scss']
})
export class DeleteUserSnackbarComponent {
  length = this.data.length;
  allow_delete = true;
  allow_remove = true;
  canDelete = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public dialog: MatDialog,
  ) {
    this.allow_delete = this.data.allow_delete;
    this.allow_remove = this.data.allow_remove;
    this.canDelete = this.data.canDelete;
  }
  snackBarRef = inject(MatSnackBarRef);

  deleteUsers() {
    this.dialog.open(DeleteUserComponent, {
      width: "440px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        users: this.data.users
      }
    }).afterClosed().subscribe((confirmed) => {
      this.data.subject.next(confirmed)
      this.clearList();
    })
  }

  removeUsers() {
    this.dialog.open(RemoveUserComponent, {
      width: "440px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        role: this.data.role,
        users: this.data.users
      }
    }).afterClosed().subscribe((confirmed) => {
      this.data.subject.next(confirmed)
      this.clearList();
    })
  }

  clearList() {
    this.data.users.forEach((user: any) => {
      user.isSelected = false;
    });
  }
}