<div class="container" aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title *ngIf="editingUser">Edit {{data.user_type}}</h1>
        <h1 mat-dialog-title *ngIf="!editingUser">Add New {{data.user_type}}</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content *ngIf="enteringEmail">
        <div class="title_light">Enter user’s email address to verify if user exists in user roster:</div>
        <mat-form-field appearance="outline" class="fullsize">
            <mat-label>Email Address</mat-label>
            <input [formControl]="emailInput" pattern="[a-zA-Z0-9._%+\-]+&#64;[a-z0-9.\-]+\.[a-z]{2,3}$" matInput
                required cdkFocusInitial placeholder="e.g. john.watson@usda.gov" (keyup.enter)="checkIfUserExist()"
                (input)="emailInputChanged()">
            <mat-error>Please enter a valid email</mat-error>
        </mat-form-field>
        <div class="green-box" *ngIf="foundUser && canEdit">
            <div class="container_message">
                <div class="subcontainer_message">
                    <i class="material-icons check-icon">check_circle</i>
                    <div>
                        <span class="title">
                            <b>{{foundUser.first_name}} {{foundUser.last_name}} ({{emailInput.value}})</b> already
                            exists in user roster and is 
                            <span *ngIf="!foundUser.region"><b>unassigned</b></span>
                            <span *ngIf="foundUser.region"> currently assigned to <b>Region {{foundUser.region}}</b>
                                <span *ngIf="foundUser.forest_name"> and <b>{{foundUser.forest_name}}</b></span>
                            </span>. Select <b>'Continue'</b> to edit this user's
                            details.
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="yellow-box" *ngIf="foundUser && !canEdit">
            <div class="container_message">
                <div class="subcontainer_message">
                    <i class="material-icons check-icon">warning</i>
                    <div>
                        <span class="title">
                            <b>{{foundUser.first_name}} {{foundUser.last_name}} ({{emailInput.value}})</b> is currently
                            assigned to <b>Region {{foundUser.region}}</b><span *ngIf="foundUser.forest_name"> and
                                <b>{{foundUser.forest_name}}</b></span>. If the user needs to be added to your forest,
                            request them to update their location instead.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-content *ngIf="!enteringEmail">
        <form id="filter_form" class="filter_form" [formGroup]="userForm">
            <div class="subtitle"><span class="red">*</span>Indicates required field</div>
            <div class="title-form">USER DETAILS</div>
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="first_name" required cdkFocusInitial>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="last_name" required cdkFocusInitial>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Email Address</mat-label>
                <input matInput formControlName="email" required cdkFocusInitial>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="half-form">
                <mat-label>Phone Number</mat-label>
                <input matInput formControlName="phone_num" cdkFocusInitial placeholder="(XXX) XXX-XXXX"
                    mask="(000) 000-0000">
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="data.user_type == 'User'">
                <mat-label>Agency</mat-label>
                <mat-select formControlName="agency_id" required
                    (selectionChange)="updateValidators($event.value, 'agency_id')">
                    @for (agency of agency_list; track agency_list) {
                    <mat-option [value]="agency.value">{{agency.text}}</mat-option>
                    }
                </mat-select>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <div class="location-info">
                <div class="title-form">Location
                    <span class="gray-text" *ngIf="(userForm.controls['agency_id'].getRawValue() ?? 0) == 0">(Home
                        unit)</span>
                    <span class="gray-text" *ngIf="(userForm.controls['agency_id'].getRawValue() ?? 0) != 0">(Closest
                        Forest Service Office)</span>
                </div>
                <mat-radio-group required formControlName="location_id" class="radio-buttons"
                    (change)="updateValidators($event.value, 'location_id')">
                    <mat-radio-button [value]="0" color="primary" class="radio-button">Forest Office</mat-radio-button>
                    <mat-radio-button [value]="1" color="primary">Regional Office</mat-radio-button>
                    <mat-radio-button [value]="2" color="primary"
                        *ngIf="userForm.controls['agency_id'].getRawValue() == 0">Washington Office</mat-radio-button>
                </mat-radio-group>
                <div
                    *ngIf="userForm.controls['location_id'].getRawValue() == 0 || userForm.controls['location_id'].getRawValue() == 1">
                    <mat-form-field appearance="outline">
                        <mat-label>Region</mat-label>
                        <mat-select formControlName="region" required (selectionChange)="regionChanged()">
                            @for (region of regions; track region) {
                            <mat-option [value]="region">{{region}}</mat-option>
                            }
                        </mat-select>
                        <mat-error>Required Field</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="userForm.controls['location_id'].getRawValue() == 0">
                        <mat-label>Forest Name</mat-label>
                        <input #forest matInput required formControlName="forest_name" [matAutocomplete]="auto"
                            (input)="filter()" (focus)="clearForest()">
                        <mat-autocomplete requireSelection #auto="matAutocomplete"
                            (optionSelected)="forestNameSelected($event)">
                            @for (name of filtered_forest_names | async; track name) {
                            <mat-option [value]="name">{{name | forest_filter}}</mat-option>
                            }
                        </mat-autocomplete>
                        <mat-error>Required Field</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="outline" *ngIf="data.user_type == 'Coordinator'"
                    style="max-width: 100%; width:96%">
                    <mat-label>Position<span class="gray-text">(Select all that apply)</span></mat-label>
                    <mat-select formControlName="positions" required multiple>
                        @for (role of filtered_roles; track role) {
                        <mat-option [value]="role.value">{{role.text}}</mat-option>
                        }
                    </mat-select>
                    <mat-error>Required Field</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="data.user_type == 'Line Officer'"
                    style="max-width: 100%; width:96%">
                    <mat-label>Line Officer Position</mat-label>
                    <mat-select formControlName="lo_position" required>
                        @for (role of filtered_roles; track role) {
                        <mat-option [value]="role.value">{{role.text}}</mat-option>
                        }
                    </mat-select>
                    <mat-error>Required Field</mat-error>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button *ngIf="!enteringEmail && editingUser" class="usa-button" [disabled]="!userForm.valid"
                (click)="saveUser()">Update</button>
            <button *ngIf="!enteringEmail && !editingUser" class="usa-button" [disabled]="!userForm.valid"
                (click)="saveUser()">Save</button>
            <button *ngIf="enteringEmail" class="usa-button" [disabled]="!emailInput.valid || !canEdit"
                (click)="checkIfUserExist()">Continue <i class="material-icons">chevron_right</i></button>
        </div>
    </div>
</div>