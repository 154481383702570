<main id="main-content" class="main-content">
    <div class="subtitle"><span class="red-normal">*</span>Indicates required field</div>
    <h2 class="form-header">5. Recommended Treatment Narratives</h2>
    <div class="subtitle-big">
        Add a treatment narrative to each treatment type.
        <br>
        Note: The total cost for implementation of all treatment recommendations is for the full calendar year.
    </div>

    <div class="yellow-warning">
        <fa-icon [icon]="faWarning" class="faWarning" aria-label="Warning"></fa-icon>
        <div class="support-text">
            <b>The treatments below have been sourced from GIS data.</b>
            <ul>
                <li>
                    Manual edits made below will be overwritten when GIS data is updated.
                </li>
                <li>
                    Any new treatments added to this report must also be added to the GIS data.
                </li>
            </ul>
        </div>
    </div>
    <div *ngFor="let forest of forests; let i = index" class="forest-container">
        <h2 class="forest-title">{{ forest }}</h2>
        <h3 class="treatment-title">Treatments <span class="thin"><i>(Source: GIS):</i></span> {{ countGISTreatments(i) }} | New Treatments: {{ countNewTreatments(i) }} | Total Treatments: {{ treatments[i].length }}</h3>
        <button class="usa-button usa-button--secondary medium-length add-treatment-btn" (click)="addNewTreatment(i)">
            <mat-icon>add</mat-icon>Add New Treatment
        </button>
        <mat-expansion-panel class="treatment-container" [expanded]="true" hideToggle *ngFor="let treatment of treatments[i]; let j = index" (opened)="treatment.open = true" (closed)="treatment.open = false">
            <mat-expansion-panel-header>
                
                <mat-panel-title class="treatment-title">
                    <i class="material-icons green-arrow" *ngIf="treatment.open">keyboard_arrow_down</i>
                    <i class="material-icons green-arrow" *ngIf="!treatment.open">chevron_right</i>
                    {{ forest }} | Treatment #{{ j+1 }}
                    <mat-chip *ngIf="treatment.new" class="mat-chip-new">
                        NEW
                    </mat-chip>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div [formGroup]="treatment.form">
                <div *ngIf="treatment.new">
                    <ng-container *ngTemplateOutlet="gisWarn"></ng-container>
                </div>
                <div class="whole-line">
                    <mat-form-field appearance="outline" class="half-field">
                        <mat-label>Type</mat-label>
                        <mat-select required (selectionChange)="treatmentTypeSelected($event, treatment)">
                            @for (treat of treatmentTypes; track treat) {
                            <mat-option [value]="treat">{{treat.type}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="half-field">
                        <mat-label>Treatment</mat-label>
                        <mat-select required [disabled]="treatment.type == null" (selectionChange)="standardTreatmentSelected($event, treatment)">
                            @for (treat of treatment.type?.standardTreatments; track treat) {
                            <mat-option [value]="treat">{{treat.treatment_option}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="whole-line">
                    <div class="quarter-div field-div">
                        <mat-form-field appearance="outline" class="quarter-field">
                            <mat-label>Unit Cost</mat-label>
                            <input required [disabled]="treatment.type == null" prefix="$ " mask="separator.2" thousandSeparator="," decimalMarker="." matInput aria-label="Unit Cost" formControlName="unit_cost">
                        </mat-form-field>
                    </div>
                    <div class="quarter-div">
                        <p class="data-title">Unit of Measure</p>
                        <p class="data-info">{{ treatment.standardTreatment?.unit_of_measure ?? "--"}}</p>
                    </div>
                    <div class="quarter-div">
                        <p class="data-title">Unit Qty (GIS)</p>
                        <p class="data-info" *ngIf="!treatment.new">{{ treatment.gis_qty }}</p>
                        <p class="data-info" *ngIf="treatment.new"><i>Not Available</i></p>
                    </div>
                    <div class="quarter-div field-div" *ngIf="treatment.form.value.override_GIS_qty">
                        <mat-form-field appearance="outline" class="quarter-field">
                            <mat-label>Custom Unit Qty</mat-label>
                            <input required type="number" matInput aria-label="Custom Unit Quanity" formControlName="custom_qty">
                        </mat-form-field>
                    </div>
                    <div class="quarter-div">
                        <p class="data-title">Total Cost</p>
                        <p *ngIf="!treatment.form.value.override_GIS_qty" class="data-info">${{ (treatment.gis_qty * treatment.form.value.unit_cost) | number: '1.2-2' }}</p>
                        <p *ngIf="treatment.form.value.override_GIS_qty"class="data-info">${{ (treatment.form.value.custom_qty * treatment.form.value.unit_cost) | number: '1.0-0' }}</p>
                    </div>
                </div>
                <div class="whole-line">
                    <mat-checkbox formControlName="override_GIS_qty">
                        Override Unit Qty from GIS.
                        <br>
                        <span class="gray-text">Please document the change in the treatment narrative.</span>
                    </mat-checkbox>
                </div>
                <div class="text-div">
                    <b>Treatment Narrative</b>
                    <br>
                    <span class="gray-text">Add details about this treatment. The total cost for implementation is for the full calendar year.</span>
                </div>
                <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="treatment.editor" [toolbar]="toolbar"></ngx-editor-menu>
                    <ngx-editor [editor]="treatment.editor" placeholder="Enter Description"></ngx-editor>
                </div>
                <div class="text-div">
                    <b>Specification Sheet file link (optional)</b>
                </div>
                <div class="whole-line">
                    <mat-form-field appearance="outline" class="whole-field">
                        <input matInput formControlName="link" aria-label="Specification Sheet file link (optional)" placeholder="Enter FS Box url e.g. www.box.com/">
                        <mat-error>Please enter a valid URL.</mat-error>
                    </mat-form-field>
                </div>
                <div class="whole-line" *ngIf="treatment.new">
                    <button class="usa-button usa-button--secondary medium-length delete-treatment-btn" (click)="deleteTreatment(i, j)">
                        <fa-icon class="fa-sm trash-icon" [icon]="faTrash"></fa-icon> Remove Treatment
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
    </div>



</main>

<ng-template #gisWarn>
    <div class="yellow-warning">
        <fa-icon [icon]="faWarning" class="faWarning" aria-label="Warning"></fa-icon>
        <div class="support-text">
            <b>Treatment data not yet available in GIS database.</b>
            <ul>
                <li>
                    The Unit Quantity is not available until its added in the database.
                </li>
                <li>
                    Data entered here will be overwritten once GIS database is updated. 
                </li>
            </ul>
        </div>
    </div>
</ng-template>