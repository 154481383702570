import { Pipe } from "@angular/core";

@Pipe({ name: "statusBadge" })
export class StatusBadgePipe {
    transform(id: number): string {
        let return_val = '';
        switch (id) {
            case 0: return "PRE-SIZE UP"
            case 1: return "NEEDED"
            case 2: return "NOT NEEDED"
            case 3: return "IN PROGRESS"
            case 4: return "SUBMITTED"
            case 5: return "IN REVIEW"
            case 6: return "READY FOR SUBMISSION"
            case 7: return "SIGNATURE REQUESTED"
            case 8: return "COMPLETE"
            case 9: return "READY FOR REVIEW"
            case 10: return "FORWARDED TO W.O."
            case 11: return "FUNDING APPROVED"
            default: return id.toString();
        }
    }
}