import { Component, OnInit, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class AssessmentsComponent implements OnInit {
  //icons
  faFire = faFire;

  //access
  isTeamLead: boolean = false;
  isROCO: boolean = false;
  isFOCO: boolean = false;
  isWO: boolean = false;
  user: any;

  tab = input.required<number>();
  tabIndex = 0;

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly userService: UserService,
  ) {
    this.titleService.setTitle("BAER - Assessments")
  }

  ngOnInit(): void {
    if (this.tab()) {
      this.tabIndex = this.tab()
    }
    this.loadUser();
  }

  loadUser() {
    this.userService.getUserProfile().then(async (user) => {
      this.user = user;
      // 7-Washington Office Director
      // 0-Reporting Tool Administrator
      // 4-BAER National Coordinator
      // 16-WO Coordination
      // 17-WO Logistics
      this.isWO = user.baer_roles.find((obj: any) => [0, 4, 7, 16, 17].includes(obj.value))
      // 12- RO BAER Coordinator
      this.isROCO = user.baer_roles.find((obj: any) => [12].includes(obj.value))
      // 5-BAER Forest Unit Coordinator
      // 8- Forest BAER Coordinator
      this.isFOCO = user.baer_roles.find((obj: any) => [5, 8].includes(obj.value))
      // 1-BAER Team Leader
      this.isTeamLead = user.baer_roles.find((obj: any) => [1].includes(obj.value))
    });
  }
}
