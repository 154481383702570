import { Component, OnChanges, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportTypeModalComponent } from './report-type-modal/report-type-modal.component';
import { FullPhasedModalComponent } from './full-phased-modal/full-phased-modal.component';

@Component({
  selector: 'app-report-type',
  templateUrl: './report-type.component.html',
  styleUrls: ['./report-type.component.scss']
})


export class ReportTypeComponent implements OnChanges {

  report = input.required<any>();
  nav = input.required<string>();

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    //TODO - save and close triggered. save report progress and return to overview page
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 2 } });
        break;
      }
      case 'back': {
        //no back from this page
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  reportTypeModal(event: any): void {
    event.stopPropagation();
    this.dialog.open(ReportTypeModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }

  fullVsPhasedModal(event: any): void {
    event.stopPropagation();
    this.dialog.open(FullPhasedModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }
}
