import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faTrash, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Editor, Toolbar } from 'ngx-editor';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-recommended-treatment',
  templateUrl: './recommended-treatment.component.html',
  styleUrls: ['./recommended-treatment.component.scss']
})


export class RecommendedTreatmentComponent implements OnChanges, OnInit {

  faTrash = faTrash;
  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;

  toolbar: Toolbar = [
    ["bold", "italic", "underline", "strike", "background_color", "bullet_list", "ordered_list"]
  ]

  editors: Editor[] = [];

  report = input.required<any>();
  nav = input.required<string>();
  forests = []
  treatments: any[][] = [];

  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  treatmentTypes: any[] = []

  im = new Inputmask("currency", {
    groupSeparator: ',',
    prefix: '$',
    allowMinus: false
  });

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,
  ) {
    this.titleService.setTitle("BAER - Report Type")
    this.initTreamentOptions()
  }

  ngOnInit(): void {
    this.forests = this.report().assessment.forest_name.split(",")
    this.forests.forEach((forest: string) => {
      let formGroup = this.formBuilder.group({
        unit_cost: [0, this.im],
        link: ["",Validators.pattern(this.reg)],
        custom_qty: [0],
        override_GIS_qty: [false],
      });

      this.treatments.push([{
        form: formGroup,
        editor: new Editor(),
        gis_qty: 7, //placeholder
      }])
    })
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    this.save()
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 6 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 4 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save() {

  }

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.router.navigate(['fire-overview'], { queryParams: {assessment_id: this.report().assessment_id } });
  }

  treatmentTypeSelected(event: any, treatment: any) {
    treatment.type = event.value
  }

  standardTreatmentSelected(event: any, treatment: any) {
    treatment.standardTreatment = event.value
  }

  addNewTreatment(forestIndex: number) {
    let formGroup = this.formBuilder.group({
      unit_cost: [0],
      link: ["",Validators.pattern(this.reg)],
      custom_qty: [0],
      override_GIS_qty: [true],
    });

    this.treatments[forestIndex].push({
      form: formGroup,
      editor: new Editor(),
      new: true,
    })
  }

  deleteTreatment(forestIndex: number, treatmentIndex: number) {
    this.treatments[forestIndex].splice(treatmentIndex, 1)
  }

  countGISTreatments(forestIndex: number) {
    let count = 0
    this.treatments[forestIndex].forEach((treatment: any) => {
      if (treatment.new == undefined) {
        count++;
      }
    })
    return count
  }

  countNewTreatments(forestIndex: number) {
    let count = 0
    this.treatments[forestIndex].forEach((treatment: any) => {
      if (treatment.new) {
        count++;
      }
    })
    return count
  }

  initTreamentOptions() {
    this.reportService.getTreatmentOptions().subscribe((response) => {
      this.treatmentTypes = []
      response.forEach((item: any) => {
        let existingTreatment = this.treatmentTypes.find((x: any) => x.type == item.treatment_type.treatment_type)
        if (existingTreatment) {
          existingTreatment.standardTreatments.push(item)
        } else {
          this.treatmentTypes.push({
            type: item.treatment_type.treatment_type,
            standardTreatments: [item],
          })
        } 
      })
    })
  }
}
