import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    reportRelations = '&relations=users,users.skills,users.user,users.user.skills,users.user.skills.skill,assessment,supporting_files,supports,soil_burn_severities,comments,forests,report_status';

    constructor(private readonly httpClient: HttpClient) { }

    getReportList(assessment_id: number, reportRelations: string): Observable<any> {
        let params = new HttpParams()
        let queryParams = `?assessment_id=${assessment_id}`
        return this.httpClient.get(
            `${environment.apiUrl}/report${queryParams}${reportRelations}`, { params: params }
        );
    }
    getReport(report_id: number): Observable<any> {
        let params = new HttpParams()
        let queryParams = `?report_id=${report_id}`
        return this.httpClient.get(
            `${environment.apiUrl}/report${queryParams}${this.reportRelations}`, { params: params }
        );
    }

    createReport(assessment: any, report_num: number): Observable<any> {
        let data = {
            assessment_id: assessment.assessment_id,
            report_status_id: 0, // In Progress
            report_num: report_num,
        };
        let params = new HttpParams()
        return this.httpClient.post(
            `${environment.apiUrl}/report`, data, { params: params }
        );
    }

    updateReport(data: {
        report_id: number,
        supports?: any,
        // Many more options to be added
    }): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/report`, data, { params: params }
        );
    }

    /**
   * Sends request for support for report, updates report with data
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
    requestSupport(data: {
        report_id: any,
        support_needed_type?: string,
        support_skills?: string,
        support_details?: string,
        other_skill?: string,
    }): Observable<any> {
        let body = {
            report_id: data.report_id,
            supports: [{
                support_type : data.support_needed_type,
                support_skills: data.support_skills,
                additional_details : data.support_details,
                support_completed: false,
                other_skill: data.other_skill,
            }],
        }
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/report`, body, { params: params }
        );
    }

    createBurnSeverity(data: {
        report_id: number,
        ownership: string,
        acres_burned: number,
        percent_burned: number,
        burn_acres_unburned: number,
        burn_acres_low: number,
        burn_acres_moderate: number,
        burn_acres_high: number,
    }): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.post(
            `${environment.apiUrl}/report-soil-burn-severity`, data, { params: params }
        );
    }

    editBurnSeverity(report_soil_burn_severity_id : number, data: {
        report_id: number,
        ownership: string,
        acres_burned: number,
        percent_burned: number,
        burn_acres_unburned: number,
        burn_acres_low: number,
        burn_acres_moderate: number,
        burn_acres_high: number,
    }): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/report-soil-burn-severity/`+report_soil_burn_severity_id, data, { params: params }
        );
    }

    getReportForestTreatments(report_id: number) {
        let report_info = [
            {
                report_forest_id: 1,
                region: '02',
                forest_org_code: '3225',
                forest_name: 'a forest name',
                //all info in report_forest table
                forest_treatment: [
                    {
                        forest_treatment_id: 1,
                        forest_id: 1, //foregn key
                        treatment_type_id: 1,
                        treatment_type: {
                            treatment_type_id: 1,
                            treatment_type: 'Plants'
                        },
                        treatment_option_id: 1,
                        treatment_option: {
                            treatment_option_id: 1,
                            treatment_option: 'P1a. Invasive EDRR',
                            code: 'some code',
                            unit_of_measure: 'Cost/Site'
                        },
                        unit_cost: 23.32,
                        unit_qty: 5,
                        narrative: 'narrative here',
                        cost_approval: 343.45,
                        recommendation_id: 1
                    }
                ]
            },
            {
                report_forest_id: 2,
                region: '02',
                forest_org_code: '3226',
                forest_name: 'another forest name',
                //all info in report_forest table
                forest_treatment: [
                    {
                        forest_treatment_id: 1,
                        forest_id: 1, //foregn key
                        treatment_type_id: 1,
                        treatment_type: {
                            treatment_type_id: 1,
                            treatment_type: 'Plants'
                        },
                        treatment_option_id: 1,
                        treatment_option: {
                            treatment_option_id: 1,
                            treatment_option: 'P1a. Invasive EDRR',
                            code: 'some code',
                            unit_of_measure: 'Cost/Site'
                        },
                        unit_cost: 27.32,
                        unit_qty: 20,
                        narrative: 'narrative here',
                        cost_approval: 343.45,
                        recommendation_id: 1
                    }
                ]
            },
            {
                report_forest_id: 3,
                region: '02',
                forest_org_code: '3227',
                forest_name: 'EMPTY forest name',
                //all info in report_forest table
                forest_treatment: []
            }
        ]

        return Promise.resolve(report_info);
    }

    uploadFile(data: any): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.post(
            `${environment.apiUrl}/file`, data, { params: params }, 
        );
    }

    getThumbnail(supporting_files_id: number): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.get(
            `${environment.apiUrl}/file/${supporting_files_id}/thumbnail`, { params: params, responseType: "blob" as any }, 
        );
    }

    deleteFile(supporting_files_id: number): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.delete(
            `${environment.apiUrl}/file/${supporting_files_id}`, { params: params, responseType: "blob" as any }, 
        );
    }

    updateFile(supporting_files_id: number, data: any): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/file/${supporting_files_id}`, data, { params: params }, 
        );
    }

    getTreatmentOptions(): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.get(
            `${environment.apiUrl}/metadata/treatment-options`, { params: params}, 
        );
    }
}