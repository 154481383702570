import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { faWarning, faFire, faUsers } from '@fortawesome/free-solid-svg-icons';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-edit-assessment',
  templateUrl: './edit-assessment.component.html',
  styleUrl: './edit-assessment.component.scss'
})
export class EditAssessmentComponent implements OnInit {
  faWarning = faWarning;
  faFire = faFire;
  faUsers = faUsers;

  leadDisabled = false;
  interregionalDisabled = false;

  private readonly fireRecord: any = this.data.fireRecord;

  displayedColumns: string[] = ["name", "skills", "assignment_status"];

  team_needs: any[];
  userList: any[] = [];
  leadList = new MatTableDataSource<any>();
  skillList: any[] = [];
  expList: any[] = [];
  regions: any[];
  is_WO = false;
  is_RO = false;

  fireForm = this.formBuilder.group({
    notes: [""],
    teamLeads: [null],
    team_needs_id: [{ value: -1, disabled: false }],
    close_out_date: [{ value: null, disabled: false }] as unknown as Date[],
    tentative_start_week: [{ value: null, disabled: false }] as unknown as Date[],
    trainees_needed: [{ value: false, disabled: false }],
    search_skills: [""],
    skill_level: [""],
    availability: [""],
    user_name: [""],
    region: [""],
    radio: [""],
  });

  constructor(
    public dialogRef: MatDialogRef<EditAssessmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    public readonly toast: ConfirmationToastComponent,
    private readonly assessmentService: AssessmentService,
    private readonly titleService: Title,
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.titleService.setTitle("BAER - Edit Team Needs")
    dialogRef.disableClose = true;
    this.regions = this.assessmentService.getRegionList().slice();
    this.regions.unshift("All Regions")

    this.team_needs = this.assessmentService.getBAERTeamNeeds();
    this.userService.getSkillList().subscribe(skillsList => {
      skillsList.forEach((skill: any) => {
        this.skillList.push({ name: skill.text, checked: false, skill_id: skill.value });
      })
      this.skillList.push({ name: "Other", checked: false, skill_id: 14 })
    });
    this.expList = this.userService.getExpList();
  }

  ngOnInit(): void {
    if (this.fireRecord.supports?.length > 0) {
      this.fireRecord.supports.forEach((support: any) => {
        if (!support.support_completed) this.fireRecord.support = true // Show banner
      })
    }
    if (this.data.fireRecord.assessment_status_id > 2)
      this.leadDisabled = true;
    else
      this.leadDisabled = false;
    this.fireForm.patchValue({
      teamLeads: this.fireRecord.team_lead_id,
      notes: this.fireRecord.notes,
      team_needs_id: this.fireRecord.team_needs_id,
      close_out_date: this.fireRecord.close_out_date,
      tentative_start_week: this.fireRecord.tentative_start_week,
      trainees_needed: this.fireRecord.trainees_needed,
      region: this.fireRecord.team_lead?.region,
    })
    this.findAvalibleLeads(this.data.isTeamLead)
  }

  getLeadName() {
    let user = this.leadList.data.find((x: any) => x.user_id == this.fireForm.getRawValue().teamLeads);
    if (user)
      return user.first_name + " " + user.last_name;
    else
      return null;
  }

  editAssessment(): void {
    if (typeof this.fireForm.getRawValue().close_out_date === 'string') {
      this.fireForm.value.close_out_date = new Date(new Date(this.fireForm.getRawValue().close_out_date || '').getTime() + 60 * 1000 * new Date().getTimezoneOffset())
    }
    if (typeof this.fireForm.value.tentative_start_week === 'string') {
      this.fireForm.value.tentative_start_week = new Date(new Date(this.fireForm.getRawValue().tentative_start_week || '').getTime() + 60 * 1000 * new Date().getTimezoneOffset());
    }
    let close_out_date: any = this.fireForm.getRawValue().close_out_date as unknown as Date
    let tentative_start_week: any = this.fireForm.getRawValue().tentative_start_week as unknown as Date
    let close_out_date_string = undefined;
    let tentative_start_week_string = undefined;
    if (close_out_date) {
      close_out_date_string = new Date(close_out_date).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", timeZone: 'UTC' })
    }
    if (tentative_start_week) {
      tentative_start_week_string = new Date(tentative_start_week).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", timeZone: 'UTC' })
    }
    let supports = undefined
    if (this.fireForm.getRawValue().teamLeads) {
      // For assessments, we can assume all support types are "Team Lead", and are completed once a team lead is assigned
      supports = this.fireRecord.supports.map((support: any) => {
        support.support_completed = true;
        return support;
      })
    }

    this.assessmentService.editAssessment({
      assessment_id: this.fireRecord.assessment_id,
      notes: this.fireForm.value.notes as string | undefined,
      team_needs_id: this.fireForm.value.team_needs_id as number | undefined,
      close_out_date: close_out_date_string,
      tentative_start_week: tentative_start_week_string,
      trainees_needed: this.fireForm.getRawValue().trainees_needed as boolean | undefined,
      team_lead_id: this.fireForm.getRawValue().teamLeads! as number | undefined,
      supports: supports
    }).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`${this.fireRecord.baer_name} assessment has been updated.`);
        this.dialogRef.close(true);
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error updating ${this.fireRecord.baer_name}.`, "Please try editing the assessment again.");
        this.dialogRef.close();
      },
    })
  }

  findAvalibleLeads(isTeamLead: boolean) {
    // 0-BAER Reporting Tool Administrator
    // 4-BAER National Coordinator
    // 7-Washington Office Director
    // 16-WO Coordination
    // 17-WO Logistics
    // 22-Washington Office Line Officer
    // 23-Acting Washington Office Line Officer
    const woOffice = this.data.user.baer_roles.find((obj: any) => [0, 4, 7, 16, 17, 22, 23].includes(obj.value))
    this.is_WO = woOffice;

    // 3-BAER Regional Coordinator
    // 12-RO BAER Coordinator
    // 13-RO BAR Coordinator
    // 14-RO READ Coordinator
    // 15-RO Disaster Coordinator
    // 20-Regional Line Officer
    // 21-Acting Regional Line Officer
    const regionOffice = this.data.user.baer_roles.find((obj: any) => [3, 12, 13, 14, 15, 20, 21].includes(obj.value))
    this.is_RO = regionOffice;
    this.dialogService.openSpinner();
    if (this.is_WO) {
      this.fireForm.get('team_needs_id')?.disable();
      this.fireForm.get('close_out_date')?.disable();
      this.fireForm.get('tentative_start_week')?.disable();
      this.fireForm.get('trainees_needed')?.disable();
    }
    this.userService.getPossibleLeadList().subscribe({
      next: (data: any) => {
        let leadList: any[] = [];
        let users = data.users;
        this.setTooltip(users);
        this.sortFlattenAssessments(users);
        if (!woOffice) {
          if (!regionOffice) {
            //forest office or simple user
            users.forEach((user: any) => {
              if (this.fireRecord.region == user.region && this.fireRecord.forest_name == user.forest_name) leadList.push(user)
            })
          }
          else {
            //region office
            users.forEach((user: any) => {
              if (this.fireRecord.region == user.region) leadList.push(user)
            })
          }
          let lead = users.find((user: any) => user.user_id == this.fireRecord.team_lead_id)
          if (lead && !leadList.includes(lead)) {
            leadList.unshift(lead)
          }
          this.userList = leadList;
        } //else wo office - filter nothing
        else {
          leadList = users;
          this.userList = users;

          //get lead's region to autofill form
          let lead = this.userList.find((user: any) => user.user_id == this.fireRecord.team_lead_id)
          if (lead) {
            this.fireForm.patchValue({
              region: lead.region,
            })
          }
        }
        this.leadList.data = leadList;

        let selectedLead = users.find((x: any) => x.user_id == this.fireRecord.team_lead_id) //check for out of region lead
        if (selectedLead && !this.is_WO) {
          if (this.fireRecord.region != selectedLead.region) {
            this.interregionalDisabled = true;
          }
        }
        this.dialogService.closeSpinner();
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error opening "Edit Team Needs".`, "Please try again.");
        this.dialogRef.close();
        this.dialogService.closeSpinner();
      }
    });
  }

  setTooltip(users: any) {
    users.forEach((user: any) => {
      let tooltip = '';
      for (let i = 1; user.skills.length - 1 >= i; i++) {
        tooltip += user.skills[i].skill.text + '\n'
      }
      user.tooltip = tooltip;
    });
  }

  sortFlattenAssessments(users: any) {
    const today = new Date();
    for (let user of users) {
      let assessments: any = [];
      assessments = [...assessments, ...user.assessments];
      for (let report of user.reports) {
        assessments = [...assessments, ...[report.assessment]];
      }
      assessments = assessments.filter((x: any) => (new Date(x.close_out_date)) > today);
      assessments.sort((a: any, b: any) => a.close_out_date > b.close_out_date);
      user.filteredAssessment = assessments[0];

      let tooltip = '';
      if (user.filteredAssessment) {
        tooltip = 'Fire Assignment:\nFire name: ' + user.filteredAssessment.baer_name
        user.filteredAssessment.start_date = (new Date(user.filteredAssessment.tentative_start_week)).toLocaleDateString("en-US")
        user.filteredAssessment.close_date = (new Date(user.filteredAssessment.close_out_date)).toLocaleDateString("en-US")
      }
      user.assessmentTooltip = tooltip;
    }
  }

  radioChange(event: any) {
    this.fireForm.patchValue({
      teamLeads: event.value.user_id
    });
  }

  filterUsers() {
    let skillList = this.fireForm.value.search_skills as any as string[]
    let skillLevels = this.fireForm.value.skill_level as any as number[]
    if (skillList && skillList.length > 0) {
      this.leadList.data = this.userList.filter((user: any) => {
        let good = false
        let userSkillList = user.skills.filter((item: any) => {
          if (skillLevels && skillLevels.length > 0) {
            if (!skillLevels.includes(item.experience_id)) return false
          }
          return true
        }).map((object: any) => object.skill.text)
        skillList.forEach((skill: string) => {
          if (userSkillList.includes(skill)) {
            good = true;
          }
        })
        return good
      });
      //move selected skill to front
      this.leadList.data = this.leadList.data.filter((e: any) => {
        e.skillIndex = e.skills.map((e: any) => e.skill.text).indexOf(skillList[0]);
        if (e.skillIndex != -1) {
          let tempSkill = e.skills[e.skillIndex];
          e.skills[e.skillIndex] = e.skills[0];
          e.skills[0] = tempSkill;
        }
        return true;
      })
    } else {
      this.leadList.data = this.userList
      // If no skill is specified, filter users with any skill at that experience level
      if (skillLevels && skillLevels.length > 0) {
        this.leadList.data = this.leadList.data.filter((user: any) => {
          return user.skills.find((item: any) => {
            return skillLevels.includes(item.experience_id)
          })
        })
      }
    }
    this.filterUsersDataFurther();
  }

  filterUsersDataFurther() {
    if (this.fireForm.value.user_name) {
      let name = this.fireForm.value.user_name.toLocaleLowerCase()
      this.leadList.data = this.leadList.data.filter((user: any) => {
        return user.first_name.toLocaleLowerCase().includes(name) || user.last_name.toLocaleLowerCase().includes(name)
      })
    }
    if (this.fireForm.value.availability && this.fireForm.value.availability.length > 0) {
      let nation_available = this.fireForm.value.availability.includes("nation")
      let region_available = this.fireForm.value.availability.includes("region")
      this.leadList.data = this.leadList.data.filter((user: any) => {
        return (nation_available && user.available_nation) || (region_available && user.available_region)
      })
    }
    if (!this.is_WO || (this.fireForm.value.region && this.fireForm.value.region != "All Regions")) {
      let region = this.fireForm.value.region
      if (!this.is_WO) { // If not WO, only show users in fire's region
        region = this.data.fireRecord.region
      }
      this.leadList.data = this.leadList.data.filter((user: any) => {
        return user.region == region
      })
    }
    if (this.fireForm.value.teamLeads && !(this.leadList.data.find((x: any) => x.user_id == this.fireForm.value.teamLeads) || false)) {
      this.fireForm.value.teamLeads = null
    }
  }
}
