import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Observable, map, of, startWith } from 'rxjs';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-edit-self',
  templateUrl: './edit-self.component.html',
  styleUrl: './edit-self.component.scss'
})
export class EditSelfComponent {

  regions: any;
  roles: any;
  filtered_roles: any;
  forest_names: any;
  filtered_forest_names: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;
  agency_list: any;
  emailInput: string;
  user_id: number = -1;
  positions?: number[];
  BAER_role_type: string | undefined = undefined

  userForm = this.formBuilder.group({
    first_name: [""],
    last_name: [""],
    region: [""],
    forest_name: [""],
    agency_id: [-1, Validators.min(0)],
    location_id: [-1],
    email: [""],
    phone_num: [""],
    positions: this.positions,
    lo_position: [null]
  });

  constructor(
    public dialogRef: MatDialogRef<EditSelfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly assessmentService: AssessmentService,
    private readonly userService: UserService,
    private readonly toast: ConfirmationToastComponent,
  ) {
    dialogRef.disableClose = true;
    this.emailInput = "";
    this.regions = this.assessmentService.getRegionList();
    this.forest_names = this.assessmentService.getForestNameList();
    this.agency_list = this.userService.getAgencyList();
    this.userService.getRoleList().subscribe((roles) => { this.roles = roles });

    this.filtered_forest_names = this.userForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )

    if (data?.user) {
      this.fillUserData(data.user)
    }

    if (data.user_type != 'User') {
      this.userForm.patchValue({
        agency_id: 0
      })
    }
  }

  fillUserData(user: any): void {
    this.userForm.patchValue(
      user
    )
    this.user_id = user.user_id;

    this.userForm.patchValue({
      positions: user.baer_roles.map((role: any) => role.value)
    })
    this.updateValidators()
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forest_names.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }

  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  saveUser(): void {
    if (this.userForm.value.location_id == 2) {
      this.userForm.value.region = "WO";
    } else if (this.userForm.value.location_id == -1) {
      this.userForm.patchValue({
        location_id: undefined
      })
    }
    this.userService.editUserDetails({
      user_id: this.user_id,
      first_name: this.userForm.value.first_name!,
      last_name: this.userForm.value.last_name!,
      region: this.userForm.value.region ?? undefined,
      forest_name: this.userForm.value.forest_name ?? undefined,
      agency_id: this.userForm.value.agency_id,
      location_id: this.userForm.value.location_id,
      phone_num: this.userForm.value.phone_num ?? undefined,
      email: this.userForm.value.email!,
      BAER_role_list: this.userForm.value.positions!,
      BAER_role_type: "ALL",
    }).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`${this.userForm.value.first_name} was successfully updated.`);
        this.dialogRef.close(true);
      },
      error: (response) => {
        this.toast.openErrorSnackBar("There was an error updating the user.", "Please try again.");
        this.dialogRef.close();
      },
    })
  }

  regionChanged(): void {
    this.forest_names = this.assessmentService.getForestNameList(this.userForm.value.region ?? undefined);
    this.filtered_forest_names = this.userForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  updateValidators(): void {
    if (this.userForm.value.agency_id != 0) {
      this.userForm.controls.location_id.clearValidators();
      this.userForm.controls.location_id.setValue(-1);
    }
    if (this.userForm.value.location_id != 0) {
      this.userForm.controls.forest_name.clearValidators();
      this.userForm.controls.forest_name.setValue(null);
    }
    if (this.userForm.value.location_id != 0 && this.userForm.value.location_id != 1) {
      this.userForm.controls.region.clearValidators();
      this.userForm.controls.region.setValue(null);
    }
  }
}