import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportService } from 'src/app/services/report.service';
import Inputmask from "inputmask";

@Component({
  selector: 'app-emergency-stabilization',
  templateUrl: './emergency-stabilization.component.html',
  styleUrls: ['./emergency-stabilization.component.scss']
})


export class EmergencyStabilizationComponent implements OnChanges, OnInit {

  report = input.required<any>();
  nav = input.required<string>();


  form = this.formBuilder.group({
    assessment_cost: [""],
  });
  displayedColumns = [
    'types',
    'treatment',
    'units',
    'unitNum',
    'unitCost',
    'total'
  ]
  footerDisplayedColumns = [
    'types',
    'total'
  ]
  forestTreatments: any;
  totalRequested = 0;

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnInit() {
        let assessmentCost = document.getElementById("assessmentCost") ?? '';
        let im = new Inputmask("currency", {
          groupSeparator: ',',
          prefix: '$',
          allowMinus: false
        });
        im.mask(assessmentCost);
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
    this.reportService.getReportForestTreatments(this.report().report_id).then((res: any) => {
      this.forestTreatments = res;
      this.totalRequestedCalc();
    })
  }

  unitTotal(treatment: any) {
    return treatment.unit_cost * treatment.unit_qty;
  }

  forestTotal(forestTreatment: any) {
    let total = 0;
    for (let treatment of forestTreatment.forest_treatment) {
      total += this.unitTotal(treatment);
    }
    return total;
  }

  totalRequestedCalc() {
    let total = 0;
    for (let forestTreatment of this.forestTreatments) {
      total += this.forestTotal(forestTreatment);
    }
    this.totalRequested = total;
  }

  navigate() {
    //TODO - save and close triggered. save report progress and return to overview page
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 7 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 5 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }
}
